<template>
  <div style="padding-bottom: 200px">
    <RocketLoad
      :full="true"
      v-if="isLoading"
    />

    <template v-else-if="billing_tier">
      <div class="grid align-items-center justify-content-between">
        <div class="flex pb-3">
          <h1>
            <template v-if="obj">Edit</template><template v-else>Add</template> Billing Tier
          </h1>
        </div>
        <div class="flex pb-3 justify-content-end align-items-center">
          <span class="mr-3 hidden sm:block">Active</span>
          <AInputSwitch v-model="billing_tier.active" />
        </div>
      </div>

      <div
        class="mb-5 mt-5"
        style="border-bottom: 1px solid #f2f4f6"
      />

      <form
        @submit.prevent="submitHandler"
        v-form-enter
      >
        <div class="p-fluid formgrid grid">
          <div class="field col-12 sm:col-6">
            <label class="label">Tier Name</label>
            <div class="p-inputgroup">
              <InputText
                placeHolder=""
                v-model="billing_tier.name"
                :disabled="checkDefaultBillingTier === true"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.billing_tier.name.required"
            >
              Tier name is required
            </div>
          </div>

          <div class="field col-12 sm:col-6">
            <label class="label">Currency</label>
            <div
              v-if="obj"
              class="p-inputgroup"
            >
              <Dropdown
                v-model="billing_tier.currency_uid"
                :disabled="
                  billing_tier.connected_clients !== 0 ||
                  billing_tier.connected_jobs !== 0 ||
                  checkDefaultBillingTier === true
                "
                :options="currency"
                optionLabel="name"
                optionValue="uid"
                placeholder=""
              >
              </Dropdown>
            </div>
            <div
              v-else
              class="p-inputgroup"
            >
              <Dropdown
                v-model="billing_tier.currency_uid"
                :options="currency"
                optionLabel="name"
                optionValue="uid"
                placeholder=""
              >
              </Dropdown>
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.billing_tier.currency_uid.required"
            >
              Currency is required
            </div>
          </div>

          <!-- <div class="col-12 sm:col-6">
            <div class="field">
              <label class="label">Available from</label>
              <Calendar
                v-model="billing_tier.apply_from"
                id="date"
                autocomplete="off"
                dateFormat="yy-mm-dd"
                :disabled="
                  checkDate === true || checkDefaultBillingTier === true
                "
                :minDate="currentDate"
                :showIcon="true"
                :selectOtherMonths="false"
                :showOtherMonths="true"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.billing_tier.apply_from.required"
            >
              Available from is required
            </div>
          </div> -->

          <div class="col-12">
            <div class="field">
              <label
                for="Description"
                class="label"
              >Description</label>
              <Textarea
                id="Description"
                rows="5"
                v-model="billing_tier.description"
                :disabled="checkDefaultBillingTier === true"
              />
            </div>
            <div
              class="error-validation-text pl-2"
              v-if="submitted && !$v.billing_tier.description.required"
            >
              Description is required
            </div>
          </div>
        </div>
        <div class="flex justify-content-between pb-2">
          <Button
            type="submit"
            label="SAVE"
            :input-class="['is-primary']"
          />
          <Button
            class="p-button-text p-button-secondary"
            @click="clearSliderContent"
          >
            CANCEL
          </Button>
        </div>
        <div class="mt-4">
          <DataTable
            :value="connected_clients"
            responsiveLayout="scroll"
            :rowClass="rowClass"
            dataKey="uid"
            :paginator="true"
            :rows="5"
          >
            <template #empty> Nothing yet... </template>
            <Column
              field="name"
              header="Connected clients"
              headerClass="w-7"
            >
              <template #body="slotProps">
                <Skeleton
                  width="50%"
                  v-if="isLoading"
                ></Skeleton>
                <template v-else>
                  <span
                    class="cursor-pointer"
                    @click="nav(slotProps)"
                  >
                    {{ slotProps.data.name }}
                  </span>
                </template>
              </template>
            </Column>
          </DataTable>
        </div>
      </form>
    </template>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { state, fetchCurrency } from "../../../services/data_service.js";
export default {
  name: "UserSliderDetails",
  components: {},
  data() {
    return {
      connected_clients: null,
      submitted: false,
      isLoading: true,
      billing_tier: {
        active: 1,
        description: null,
        name: null,
        currency_uid: null,
        apply_from: null,
      },
    };
  },
  validations: {
    billing_tier: {
      description: {
        required,
      },
      name: {
        required,
      },
      currency_uid: {
        required,
      },
      // apply_from: {
      //   required,
      // },
    },
  },
  async mounted() {
    await fetchCurrency();
    this.isLoading = false;
    this.task_type = this.obj;

    if (this.obj) {
      this.getConnectedClients();
      this.billing_tier = JSON.parse(JSON.stringify(this.obj));
    }
  },
  methods: {
    nav(ev) {
      this.clearSliderContent();
      this.$router.push("/client/" + ev.data.uid);
    },
    rowClass() {
      return "row-accessories";
    },
    getConnectedClients() {
      this.isLoading = true;
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasktype/list/billingtier/clients/" +
            this.obj.uid,
          {}
        )
        .then((response) => {
          this.connected_clients = response.data.data;
          this.isLoading = false;
        });
    },
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        this.currency.currency_uid = this.currency_uid;

        this.billing_tier.apply_from = this.$moment(
          new Date().toLocaleString("en-US")
        ).format("YYYY-MM-DD");

        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/tasktype/" +
              (!this.obj ? "create" : "update") +
              "/billingtier" +
              (!this.obj ? "" : "/" + this.obj.uid),
            JSON.stringify(this.billing_tier),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.clearSliderContent();
            this.$toast.add({
              severity: "success",
              summary: "Billing Tier Updated",
              life: 3000,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
  computed: {
    checkDefaultBillingTier() {
      if (this.billing_tier.name == "Default") {
        return true;
      } else {
        return false;
      }
    },
    checkDate() {
      var date = this.currentDate;
      date = new Date();
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();
      date = yyyy + "-" + mm + "-" + dd;

      if (this.billing_tier.apply_from < date) {
        return true;
      }
      return false;
    },
    currentDate() {
      var currentDate = new Date();
      return currentDate;
    },
    user_uid() {
      return this.$store.getters.user.uid;
    },
    user() {
      return this.$store.getters.user;
    },
    currency() {
      return state.currencies;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
  },
  watch: {},
};
</script>